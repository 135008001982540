import React from "react";
import { Taco } from "@cncxt/imodium-component-library";
import styles from "./tacoList.module.scss";
import { randomNumber } from "../../utils/miscFunctions";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { GATSBY_TACO_TITLE_COLOR } from "../../utils/constants";

interface TacoListProps {
  width: string;
  data: {
    description: JSON;
    edit_link: string;
    group: string;
    image: {
      alt: string;
      src: string;
      title: string;
    };
    link: {
      href: string;
      title: string;
    };
    title: string;
    hide_title: boolean;
    title_wrapper: string;
    view_mode: "vertical" | "horizontal";
    width: string;
  }[];
}

const TacoList = ({ data, width }: TacoListProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const enableEdit =
    process.env.GATSBY_ENABLE_EDIT && process.env.GATSBY_ENABLE_EDIT == "true"
      ? true
      : false;
  return (
    <div className={styles.tacoLayout} style={{ width: width }}>
      {data.length > 1 &&
        data.map((taco, index) => {
          const wrapperLargeDevice = {
            width: "" + taco.width + "",
          };
          return (
            <div
              key={`taco-layout-${index + randomNumber}`}
              className={styles.tacoOuterWrapper}
              style={breakpoints.medium ? wrapperLargeDevice : {}}
            >
              <div className={styles.tacoWrapper}>
                <Taco
                  type={taco.view_mode}
                  size="fullWidth"
                  title={taco.title}
                  hideTitle={taco.hide_title}
                  desc={taco.description}
                  image={taco.image.src}
                  imgAlt={taco.image.alt}
                  linkLabel={taco.link[0].title}
                  linkUrl={taco.link[0].href}
                  bgColor="#e9f4fa"
                  editLink={enableEdit ? taco.edit_link : undefined}
                  titleColor={GATSBY_TACO_TITLE_COLOR}
                />
              </div>
            </div>
          );
        })}
      {data.length == 1 &&
        data.map((taco, index) => {
          const wrapperLargeDevice = {
            width: "" + taco.width + "",
          };
          return (
            <div
              key={`taco-layout-${index + randomNumber}`}
              className={styles.tacoOuterWrapper}
              style={breakpoints.medium ? wrapperLargeDevice : {}}
            >
              <div className={styles.tacoWrapperWithoutFlex}>
                <Taco
                  type={taco.view_mode}
                  size="fullWidth"
                  title={taco.title}
                  hideTitle={taco.hide_title}
                  desc={taco.description}
                  image={taco.image.src}
                  imgAlt={taco.image.alt}
                  linkLabel={taco.link[0].title}
                  linkUrl={taco.link[0].href}
                  bgColor="#e9f4fa"
                  editLink={enableEdit ? taco.edit_link : undefined}
                  titleColor={GATSBY_TACO_TITLE_COLOR}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TacoList;
