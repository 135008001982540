import React from "react";
import { Helmet } from "react-helmet";

interface Seo {
  metaArray: {
    property_key_name: string;
    property_key_value: string;
    content: string;
  }[];
  canonicalArray: {
    ref: string;
    href: string;
  };
  hreflangArray: {
    rel: string;
    hreflang: string;
    href: string;
  }[];
}

const createMetaTags = (metaArray: Seo["metaArray"]): Array<JSX.Element> => {
  const meta = metaArray.map(obj => {
    if (obj.property_key_name === "meta")
      return (
        <meta
          name={obj.property_key_value}
          content={obj.content}
          key={obj.property_key_name + obj.property_key_value}
        />
      );
    else
      return (
        <meta
          property={obj.property_key_value}
          content={obj.content}
          key={obj.property_key_name + obj.property_key_value}
        />
      );
  });
  return meta;
};

const createHrefTags = (
  hreflangArray: Seo["hreflangArray"],
): Array<JSX.Element> => {
  const hreflang = hreflangArray.map((obj, index) => (
    <link rel={obj.rel} href={obj.href} hrefLang={obj.hreflang} key={index} />
  ));
  return hreflang;
};

const Seo = ({
  metaArray,
  canonicalArray,
  hreflangArray,
}: Seo): JSX.Element => {
  const meta = metaArray ? createMetaTags(metaArray) : [];
  const hreflang = hreflangArray ? createHrefTags(hreflangArray) : [];
  return (
    <Helmet>
      {meta}
      {canonicalArray ? (
        <link rel={canonicalArray.ref} href={canonicalArray.href} />
      ) : null}
      {hreflang}
    </Helmet>
  );
};

export default Seo;
